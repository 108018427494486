import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import "./contact.scss";

function Contact(){
    return (
        <>
        <Navbar alwaysCompact={true}/>
        <div className="Contact">
            <h1 className="font-bauhmas93 use-brand-color">Contact Us</h1>
            <div className="container mt-1">
                <a href="mailto:dkvk.jewellers@gmail.com"   className="email">
                    Email: dkvk.jewellers@gmail.com
                </a>
                <a href="https://wa.me/919837017522?text=Hi,%20got%20your%20Contact%20from%20website!" className="whatsapp">
                    Whatsapp: +917217217027
                </a>
                <a href="https://instagram.com/dkvk.jewellers" className="instagram">
                    Instagram: @dkvk.jewellers
                </a>
                <div className="container mt-1">
                    <h2>Company Details</h2>
                    <p>Deepak Kumar Vinay Kumar Jewellers,  <br /> 6, M.G. Road, Awagarh house Opp. Anjana Cinama, Agra- India, <br /> 0562-4304343 <br /> Vinay Paraswani, +919837017522</p>
                </div>      
            </div>
        </div>
        <Footer />

        </>
    )
}


export default Contact;